import api from '../../../utils/api';
import apiFile from '../../../utils/api';
import { Statuses } from '../statuses';
import { isClient, isReviewer, isStaff, onlyUnique } from '../../../utils/helpers';
import { loadCaseSessionStorageData } from '../../../utils/case-helpers';
import { FETCH_UPDATE_CASE_VALUES_SUCCESS, FETCH_UPDATE_CASE_VALUES_SUCCESS_COMMITTED } from 'redux/reducers/cases/case';
import history from '../../history'
import { API_HOST } from '../../../config';
import { getSessionStorageKey } from '../../../utils/case-helpers';

const getOwnerLink = (data) => {
    let profileRoute;

    if (isStaff(data.roleCode)) profileRoute = 'staff/profile';
    if (isReviewer(data.roleCode)) profileRoute = 'reviewers/profile';
    if (isClient(data.roleCode)) profileRoute = 'submitters/profile';

    return profileRoute;
};

export const getCase = (code, isUpdatePages) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CASE_LOADING',
    });

    return api
        .get(`case/get?code=${code}`)
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_GET_CASE_SUCCESS',
                payload: data,
                code: code,
            });
            const { user } = getState().app.auth;

            if (isStaff(user.roleCode) || user.roleCode === 'ROLE_ADMIN') getOwnersProfiles(data)(dispatch);
            if (typeof isUpdatePages !== 'undefined') {
                dispatch({
                    type: 'FETCH_UPDATE_PAGES_IN_LIST',
                    payload: data,
                    code: code,
                });
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CASE_ERROR',
                    payload: Statuses[ error.response.status ],
                });
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_CASE_ERROR',
                    payload: error.message,
                });
            }
        });
};

export const getCaseValues = (code, isSavedValuesMissing) => (dispatch) => {
    dispatch({
        type: 'FETCH_CASE_LOADING',
    });

    dispatch({
      type: 'GET_CASE_VALUES_LOADING',
    });
    return api
        .get(`case/get_values?code=${code}`)
        .then(({ data }) =>
            dispatch({
                type: isSavedValuesMissing ? 'REFRESH_LOCAL_STORAGE' : 'FETCH_GET_CASE_VALUES_SUCCESS',
                payload: data,
            }),
        )
        .catch((error) => {
            if (error?.response?.data?.error) {
              dispatch(setCaseMessage({
                text:  error?.response?.data?.error,
                type: 'error',
                time: '5000',
              }));
            }
            if (error.response) {
                dispatch({
                    type: 'FETCH_CASE_ERROR',
                    payload: Statuses[ error.response.status ],
                });
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_CASE_ERROR',
                    payload: error.message,
                });
            }
        });
};

export const getPatientCaseLock = (code) => (dispatch) => {
  let tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');
  if (!tabTimeStamp) {
    const newTabTimeStamp = new Date().getTime();
    sessionStorage.setItem(getSessionStorageKey(code) + '_tabCreatedAt', newTabTimeStamp);
    tabTimeStamp = newTabTimeStamp;
  }

  return api.get(`case/get_lock?code=${code}&tabTimeStamp=${tabTimeStamp}`)
      .then(({ data }) =>
        dispatch({
          type: 'GET_PATIENT_CASE_LOCK_INFO',
          payload: data,
        }))
      .catch((error) => {
          console.log(error)
      });
};

export const removeCaseLocking = (code, tabTimeStamp) => (dispatch, getState) => {
  const isLocked = getState().app.cases.caseLockInfo.locked;
  if(isLocked) {
    return;
  }
  return api
    .post(`case/remove_case_locking?caseCode=${code}&tabTimeStamp=${tabTimeStamp}`)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const caseHeartBeat = (code) => (dispatch) => {
  const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');
  return api.post(`case/case_heart_beat?caseCode=${code}&tabTimeStamp=${tabTimeStamp}`)
    .then(() => {
        console.log('Heartbeat success');
    })
    .catch((error) => {
      getPatientCaseLock(code)(dispatch)
    });
}

export const updateCaseValues = (code) => async (dispatch, getState) => {
    if (!code || getState().app.cases.caseLockInfo.locked) {
        //alert('Error occured during the update of the case values. Please refresh the page and try again later.');
        return;
    }

    let newValues = loadCaseSessionStorageData(code, 'values');
    let savedValues = loadCaseSessionStorageData(code, 'values_save');

    const isCaseValuesLoading = getState().app.cases.isCaseValuesLoading;
    if (!isCaseValuesLoading && (!savedValues ||
                                 !savedValues.fieldData ||
                                 !newValues ||
                                 !Object.keys(savedValues.fieldData).length ||
                                 Object.keys(savedValues.fieldData).length <= 0)) {
      getCaseValues(code, true)(dispatch)
    }
    if (newValues && savedValues && savedValues.code) {
        let changedValues = {
            active: savedValues.active,
            code: code,
            fieldData: {},
        };

        for (let fieldDataKey in savedValues.fieldData) {
            /* eslint-disable */
            if (savedValues.fieldData.hasOwnProperty(fieldDataKey) && 
                (typeof newValues[fieldDataKey] === 'string' && newValues[fieldDataKey] === '' 
                || fieldDataKey.includes('date')) 
                || newValues[fieldDataKey]) {
                if (newValues[fieldDataKey] 
                    && savedValues.fieldData[ fieldDataKey ] 
                    && newValues[ fieldDataKey ].toString() !== savedValues.fieldData[ fieldDataKey ].toString()) {
                    changedValues.fieldData[ fieldDataKey ] = newValues[ fieldDataKey ];
                    savedValues.fieldData[ fieldDataKey ] = newValues[ fieldDataKey ];
                } else if (newValues[fieldDataKey]!== savedValues.fieldData[ fieldDataKey ]){
                    changedValues.fieldData[ fieldDataKey ] = newValues[ fieldDataKey ];
                    savedValues.fieldData[ fieldDataKey ] = newValues[ fieldDataKey ];
                }
            }
            /* eslint-disable */
        }
        
        if (JSON.stringify(changedValues.fieldData) !== '{}' && changedValues.fieldData != null) {
            let fields = [];

            Object.keys(changedValues.fieldData).forEach(fieldCode =>
                fields.push(getState().app.cases.fields.find(value => value.code === fieldCode))
            );

            dispatch({
                type: 'FETCH_CASE_LOADING',
            });
            dispatch({
                type: FETCH_UPDATE_CASE_VALUES_SUCCESS,
                payload: changedValues,
            });

            const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');

            if (
              !newValues.caseCode ||
              !savedValues.fieldData?.caseCode ||
              (newValues.caseCode !== savedValues.fieldData.caseCode || newValues.caseCode !== code)
            ) {
      
              await api
                  .post(`case/save_logging?caseCode=${code}&info=MISMATCH`, JSON.stringify(sessionStorage))
                  .then(() => {
                      dispatch({
                          type: 'FETCH_EDIT_CASE_ERROR',
                          payload: 'A network error occurred during our last save attempt. Please, save the contents of the last field you edited and reload the page. If the error persists, try logging out and logging in again.',
                      });
                  })
                  .catch((error) => {
                      console.error('API error:', error);
                  });
                  return;
            } else if (Object.keys(changedValues.fieldData).length > 3) {
              await api
              .post(`case/save_logging?caseCode=${code}&info=INFO`, JSON.stringify(sessionStorage))
            }
            return api
                .post(`case/update_values?tabTimeStamp=${tabTimeStamp}`, changedValues)
                .then(() => {
                    dispatch({
                        type: FETCH_UPDATE_CASE_VALUES_SUCCESS_COMMITTED,
                        payload: changedValues,
                    });
                    for (let field of fields) {
                        field.updateSuccess = true
                        dispatch({
                          type: 'FIELD_UPDATE_SUCCESS',
                          payload: field,
                        });
                    }
                })
                .catch((error) => {
                    if  (error.response && error.response.status === 423) {
                        getPatientCaseLock(code)(dispatch)
                        getCaseValues(code)(dispatch)
                    }
                    for (const field of fields) {
                        field.updateSuccess = false
                        dispatch({
                            type: 'FIELD_UPDATE_ERROR',
                            payload: field,
                        });
                    }
                    if (error.response) {
                        dispatch({
                            type: 'FETCH_CASE_ERROR',
                            payload: Statuses[ error.response.status ],
                        });
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error ', error.message);
                        dispatch({
                            type: 'FETCH_CASE_ERROR',
                            payload: error.message,
                        });
                    }
                });
        }
    }
};

/*export const attachFile = (data) => (dispatch, getState) => {
  const fileUploadErrors = [];
  const fileUploadLimit = parseInt(getState().app.auth.user.fileUploadLimit.match(/\d+/)[0], 10) * 1024 * 1024;
  
  if(data.file.size > fileUploadLimit) {
      const fileInfo = {
        name: data.file.name,
        size: data.file.size,
      };
      fileUploadErrors.push(fileInfo);
  }
  
  dispatch({
    type: 'UPLOAD_FILES_ERROR',
    payload: {
    fileUploadErrors: fileUploadErrors,
    },
  });

    dispatch({
        type: 'FETCH_ATTACH_CASE_FILE_LOADING',
    });

    if(fileUploadErrors.lenth > 0) {
      return dispatch({
        type: 'FETCH_CASE_FILE_ERROR',
        payload: Statuses[ err.response ] || Statuses[ 0 ],
    });
  }  


    let formData = new FormData();
    let visibleToRoles = [];
    if (data.type === 'CLIENT_ATTACHMENT') visibleToRoles = ['ROLE_CLIENT_ADMIN', 'ROLE_CLIENT_USER', 'ROLE_CLIENT_SUPERVISOR'];
    if (data.type === 'REVIEWER_ATTACHMENT') visibleToRoles = ['ROLE_REVIEWER'];
    let settings = {
        active: true,
        name: data.file.name,
        type: 'MEDICAL_RECORDS',
        userType: data.type,
        caseCode: data.code,
        userCode: data.userCode,
        user: data.userCode,
        // This attachment will be counted if the user has got client role and the attachement is uploaded in DRAFT stage.
        counted: isClient(getState().app.auth.user.roleCode) ? 'CW_DRAFT' === getState().app.cases.editedCase.currentStage.code : data.type !== 'REVIEWER_ATTACHMENT',
        includedToMerge: data.type !== 'REVIEWER_ATTACHMENT',
        date: Date.now(),
        visibleToRoles: visibleToRoles,
    };
    formData.append('file', data.file);
    formData.append('settings', new Blob([JSON.stringify(settings)], { type: 'application/json' }));

    return apiFile
        .post('case/attach_file', formData)
        .then(() => {
            dispatch({
                type: 'FETCH_CASE_FILE_SUCCESS',
            });
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: 'FETCH_CASE_FILE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            });
        });
};*/

export const attachFiles = (attachedFilesWrapper) => async (dispatch, getState) => {

  const fileUploadErrors = [];
    const fileUploadLimit = parseInt(getState().app.auth.user.fileUploadLimit.match(/\d+/)[0], 10) * 1024 * 1024;
    let fileSizeSum = 0;
    Array.from(attachedFilesWrapper.files).forEach(file => {
      fileSizeSum+=file.size;
      if(file.size > fileUploadLimit || fileSizeSum > fileUploadLimit) {
        const fileInfo = {
          name: file.name,
          size: file.size,
        };
        fileUploadErrors.push(fileInfo);
      }
    });
    
    dispatch({
      type: 'UPLOAD_FILES_ERROR',
      payload: {
      fileUploadErrors: fileUploadErrors,
      },
    });
    
    if(attachedFilesWrapper.files.length === fileUploadErrors.lenth) {
        return dispatch({
          type: 'FETCH_CASE_FILE_ERROR',
          payload: Statuses[ err.response ] || Statuses[ 0 ],
      });
    }  
    

    dispatch({
        type: 'FETCH_ATTACH_CASE_FILE_LOADING',
    });

    fileSizeSum = 0;
    let formData = new FormData();
    Array.from(attachedFilesWrapper.files).forEach((file, index) => {
      fileSizeSum+=file.size;
      if(file.size <= fileUploadLimit && fileSizeSum <= fileUploadLimit) {
          formData.append('files', file)
          if (attachedFilesWrapper.passwords[index]) {
            formData.append(`file_password_${file.name}`, attachedFilesWrapper.passwords[index]);
          }
      }
    })

    const {code} = getState().app.cases.editedCase;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(getState().app.cases.editedCase.code) + '_tabCreatedAt');

    return apiFile
        .post(`case/attach_files?tabTimeStamp=${tabTimeStamp}&caseCode=${code}`, formData)
        .then((result) => {
            dispatch({
                type: 'FETCH_CASE_FILE_SUCCESS',
            });
            return result.data;
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: 'FETCH_CASE_FILE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            });
            dispatch(setCaseMessage({
              text:  err?.response?.data?.error,
              type: 'error',
              time: '5000',
            }));
            if(err?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            } 
        });
};


export const detachFile = (code) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_DETACH_CASE_FILE_LOADING',
    });
    let item = getState().app.cases.files.find((item) => {
        return item.code === code.code ? item : null;
    });
    const oldItem = { ...item };

    const caseCode = getState().app.cases.editedCase.code;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(caseCode) + '_tabCreatedAt');

    return apiFile
        .post(`case/detach_file?attachmentCode=${code.code}&tabTimeStamp=${tabTimeStamp}&caseCode=${caseCode}`)
        .then(() => {
            dispatch({
                type: 'FETCH_CHANGE_MERGE_ON_DETACH_FILE',
                payload: {
                    files: getState().app.cases.files,
                    oldItem: oldItem,
                    caseId: getState().app.cases.code,
                    pagesCount: getState().app.cases.pagesCount,
                },
            });
            dispatch({
                type: 'FETCH_DETACH_FILE_SUCCESS',
            });
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_CASE_FILE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            });
            if(err?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            } 
        });
};

const getStageDates = (caseData, stage) => {
    const { creationDate, submitDate, dispatchDate, reviewDate, completionDate } = caseData;
    let dates = {};

    switch (stage) {
        case 'ownerDraft':
            dates.enterStageDate = creationDate;
            dates.submitStageDate = submitDate;
            break;
        case 'ownerDispatch':
            dates.enterStageDate = submitDate;
            dates.submitStageDate = dispatchDate;
            break;
        case 'ownerReview':
            dates.enterStageDate = dispatchDate;
            dates.submitStageDate = reviewDate;
            break;
        case 'ownerQa':
            dates.enterStageDate = reviewDate;
            dates.submitStageDate = completionDate;
            break;
        default:
    }

    return dates;
};

export const clearFilesError = () => (dispatch) => {
  dispatch({
      type: 'CLEAR_FILES_ERROR',
  });
} 

export const getOwnersProfiles = (caseData) => (dispatch) => {
    dispatch({
        type: 'FETCH_GET_CASE_OWNERS_LOADING',
    });

    const { ownerDraft, ownerDispatch, ownerReview, ownerQa } = caseData;
    const stageToCode = { ownerDraft, ownerDispatch, ownerReview, ownerQa };

    const stages = Object.keys(stageToCode).filter((item) => stageToCode[ item ]);
    const profileCodes = Object.values(stageToCode)
        .filter((item) => item)
        .filter(onlyUnique);

    Promise.all(
        profileCodes.map((item) => {
            return api.get(`auth/user/get?code=${item}`);
        }),
    )
        .then((responses) => {
            const ownersInfo = stages.reduce((stages, stage) => {
                const profile = responses.find((item) => item.data.code === stageToCode[ stage ]).data;
                let dates = getStageDates(caseData, stage);
                const link = profile.roleCode === 'ROLE_ADMIN' ? null : `/#/${getOwnerLink(profile)}/${profile.code}`;

                return {
                    ...stages,
                    [ stage ]: {
                        code: profile.code,
                        name: profile.lastName + ' ' + profile.firstName,
                        stage: stage,
                        action: 'action',
                        link,
                        ...dates,
                    },
                };
            }, {});

            dispatch({
                type: 'FETCH_GET_OWNERS_PROFILES_SUCCESS',
                payload: ownersInfo,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_CASE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            });
        });
};

export const changeCaseOwner = (stage, owner) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_EDIT_CASE_LOADING',
    });
    const { editedCase } = getState().app.cases;
    const dates = getStageDates(editedCase, stage);
    const caseCode =  getState().app.cases.editedCase.code;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(caseCode) + '_tabCreatedAt');
    editedCase.tabTimeStamp = tabTimeStamp;
    console.log('editedCase', editedCase);
    return api
        .post(`case/update?tabTimeStamp=${tabTimeStamp}`, {
            ...editedCase,
            tabTimeStamp,
            [ stage ]: owner.code,
        })
        .then(() => {
            dispatch({
                type: 'FETCH_CHANGE_STAGE_OWNER_SUCCESS',
                payload: {
                    code: owner.code,
                    name: owner.lastName + ' ' + owner.firstName,
                    link: `/#/${getOwnerLink(owner) || 'home'}/${owner.code}`,
                    action: 'action',
                    stage,
                    ...dates,
                },
                stage,
            });
            dispatch({
                type: 'CLOSE_CREATE_MODAL',
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_EDIT_CASE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            })
            if(err?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            } 
        });
};

export const deleteStageOwner = (stage) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_EDIT_CASE_LOADING',
    });
    const { editedCase } = getState().app.cases;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(editedCase.code) + '_tabCreatedAt');
    editedCase.tabTimeStamp = tabTimeStamp;
    return api
        .post(`case/update?tabTimeStamp=${tabTimeStamp}`, {
            ...editedCase,
            [ stage ]: null,
        })
        .then(() => {
            dispatch({
                type: 'FETCH_DELETE_STAGE_OWNER_SUCCESS',
                payload: stage,
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_EDIT_CASE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            })
            if(err?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            } 
          });
};

export const getCaseFiles = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_GET_CASE_FILES_LOADING',
    });

    return api
        .get('case/list_attachments?caseCode=' + data)
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_GET_CASE_FILES_SUCCESS',
                payload: data,
                caseId: getState().app.cases.editedCase.caseId,
                pagesCount:
                    typeof getState().app.cases.editedCase.mergedPagesCount !== 'undefined' ? getState().app.cases.editedCase.mergedPagesCount : 0,
            });
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_GET_CASE_FILES_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            });
        });
};

export const updateFile = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_UPDATE_CASE_FILE_LOADING',
    });

    const caseCode = getState().app.cases.editedCase.code;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(caseCode) + '_tabCreatedAt');

    return api
        .post(`case/update_file?attachmentCode=${data.attachmentCode}&caseCode=${caseCode}&tabTimeStamp=${tabTimeStamp}`, JSON.stringify(data.settings))
        .then(() => {
            dispatch({
                type: 'FETCH_UPDATE_CASE_FILE_SUCCESS',
            });
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_UPDATE_CASE_FILE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ],
            });
            if(err?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            } 
        });
};

export const getCaseForCheckValid = (data) => (dispatch) => {
    return getCaseValues(data)(dispatch).then(() => {
        api.get('template/case?case_code=' + data).then((response) => {
            // const {} = response;
            dispatch({
                type: 'FETCH_GET_CASE_FOR_CHECK',
                payload: response,
            });
        });
    });
};

export const removeCase = () => (dispatch, getState) => {
  dispatch({
      type: 'FETCH_EDIT_CASE_LOADING',
  });
  const {code} = getState().app.cases.editedCase;
  const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');

  return api.post(`case/remove?code=${code}&tabTimeStamp=${tabTimeStamp}`)
      .then(() => {
          history.push('/home');
          fetchCaseCount()(dispatch)
      })
      .catch((error) => {
          if(error?.response?.status == 423) {
            getPatientCaseLock(code)(dispatch);
          }
          dispatch({
              type: 'FETCH_EDIT_CASE_ERROR',
              payload: error?.response?.data?.error,
          });
      })
};


export const updateCaseLocking = (shouldRefresh) => (dispatch, getState) => {
  const { code } = getState().app.cases.editedCase;
  dispatch({
    type: 'FETCH_EDIT_CASE_LOADING',
  });
  
  const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');
  
  return api
    .post(`case/update_case_locking?caseCode=${code}&tabTimeStamp=${tabTimeStamp}`)
    .then(() => {
      if(shouldRefresh) {
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: 'FETCH_EDIT_CASE_ERROR',
        payload: Statuses[err.response] || Statuses[0],
      });
    });
};



export const editField = (field, value) => {
    return {
        type: 'EDIT_CASE_FIELD',
        payload: {
            [ field ]: value,
        },
    };
};

export const prepareFields = (data) => {
    return {
        type: 'PREPARE_CASE_FIELDS',
        payload: data,
    };
};

export const prepareCaseAttributes = (data) => {
    return {
        type: 'PREPARE_CASE_ATTRIBUTES',
        payload: data,
    };
};

export const setSubmitAfterLoad = (data) => {
    return {
        type: 'SUBMIT_AFTER_LOAD',
        payload: data,
    };
};

export const setSubmitAfterValidation = (data) => {
    return {
        type: 'SUBMIT_AFTER_VALIDATION',
        payload: data,
    };
};

export const saveMoveToStage = (stage) => {
    return {
        type: 'EDIT_CASE_STAGE_FIELD',
        payload: stage,
    };
};

export const clearEdited = () => {
    return {
        type: 'CLEAR_EDITED_CASE',
        payload: '',
    };
};

export const isAllValid = (valid) => {
    return {
        type: 'VALIDATE_CASE_FIELD',
        payload: valid,
    };
};

export const showNotValidMessage = (show) => {
    return {
        type: 'SHOW_NOT_VALID_CASE',
        payload: show,
    };
};

export const setCaseMessage = (data) => {
    return {
        type: 'SET_CASE_MESSAGE',
        payload: {
            text: data.text,
            type: data.type || 'success',
            time: data.time || '',
        },
    };
};

export const clearCaseMessages = () => {
    return {
        type: 'CLEAR_CASE_MESSAGES',
        payload: ''
    };
};

export const throwCaseError = (message) => {
    return {
        type: 'FETCH_CASE_ERROR',
        payload: message,
    };
};

export const setCaseIsCreated = (state) => {
    return {
        type: 'CASE_IS_CREATED',
        payload: state,
    };
};

export const saveTableWidth = (width) => {
    return {
        type: 'SAVE_TABLE_WIDTH',
        payload: width,
    };
};

export const disableReviewerButtons = (state) => {
    return {
        type: 'DISABLE_REVIEWER_BUTTONS',
        payload: state,
    };
};

export const setNeedToReloadAssignments = (state) => {
    return {
        type: 'SET_NEED_RELOAD_ASSIGNMENTS',
        payload: state,
    };
};
